<template>
	<el-dialog title="用户编辑图片模板" :visible.sync="dialogVisible" width="1112px">

		<Pay ref="pay" :message1="checkedGift" />


		<EditsInfo ref="EditsInfo" :info="info" />
		<EditsImgInfo ref="EditsImgInfo" :info="info" />

		<div class="gifts clearFix">
			<div style="width: 80%; display:block; float: left;">


				<div v-for="(item, i) in temps" :span="41" style="width: 100%!important;height: 100px;">

					<label style="float:left">
						<input v-model="checkedLine" :value="item.line"   style="margin-top:26px" 
						name="Fruit" type="checkbox" />
					</label>
					
					<div style="float:left">
						<ul>
							<li style="list-style: none; float: left;" v-for="(item2, i2) in item.data">

								<img style="width: 30px;" :src="item2.img">

							</li>
						</ul>
					</div>
					
					<br />
				</div>



			</div>

			<div style="width: 4%; display:block;float: right;">

				<button @click="goDel(2)">删除</button><br /><br />
			 
				<button @click="goChange()">修改</button><br /> <br />
				
				
				<button @click="goSend()">使用</button><br /> <br /> 


				<button @click="add(2)">添加</button><br /> <br />

			</div>

			<!--    <el-pagination
          small
          layout="prev, pager, next"
          :page-count="gifts.length"
          :current-page.sync="index"
          hide-on-single-page
      /> -->
		</div>
		<!-- <div class="info">
      <div>赠送：<span>{{info.name}}</span>(ID: {{info.id}}, {{info.sex}}, {{info.age}}, {{info.major}})</div>
      <div>礼物：
        <span style="margin-right: 20px">{{checkedGift.name}}</span>
        <el-input-number v-model="num" :min="1" :max="10" size="mini"/>
      </div>
      <div>祝福语：
        <el-input
            type="textarea"
            :rows="2"
            placeholder="输入祝福语"
            v-model="textarea"
            style="margin-top: 10px"
        />
      </div> -->
		</div>
		<!--    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleSend3">赠送</el-button>
    </span> -->
	</el-dialog>


</template>

<script>
	import axios from "axios";
	import data from '@/data/data.js'
	import Pay from './Pay.vue'
	import EditsInfo from '@/components/EditsInfo'
	import EditsImgInfo from '@/components/EditsImgInfo'

	export default {
		name: "AddImgCaseInfo",
		components: {
			Pay,
			EditsInfo,
			EditsImgInfo
		},
		props: ['info'],
		data() {
			return {
				checkedLine:[],
				dialogImageUrl: '',
				dialogVisible2: false,
				disabled: false,
				messageToHelloWorld: '我是父组件向下传递的信息',
				id: 0,
				dialogVisible: false,
				gifts: [],
				index: 0,
				checkedGift: {
					id: 60,
					uid: 60,
					name: '玫瑰花',
					url: require('../assets/gifts/5.jpg'),
					bid: 0,
					num: 0,
					message: '',

				},
				finfo: {},
				num: 1,
				textarea: '',
				temps: [],
				contents: [],
				case_id: 0,
				data: '我是赋值的内容',
				file_id:0,
			};
		},
		created() {
			const params1 = new URLSearchParams();
			params1.append('id', this.case_id);


			axios.post(this.apiUrl + '/api/User/getCaseImgTemp', params1)
				.then((response) => {

					this.temps = response.data.data;

				})
				.catch(function(error) {
					console.log(error);
				});
		},

		watch: {
			info: function(newVal, oldVal) {
				this.finfo = newVal; //newVal即是chartData
				this.drawChart();
			}
		},
		mounted() {
			//this.formatGifts(data.gifts)

			//礼物列表




		},
		
		props: {
		      goSendImgs: {
		        type: Function,
		        default: null
		      }
		    },
		methods: {
			
			//发送图片
			goSend(){ 
				
				this.$parent.goSendImgs(this.checkedLine,this.file_id) ;
				
				// this.dialogVisible = false;
				this.dialogVisible = false;
				
			},
			//修改图片 
			goChange(){  
				this.$refs.EditsImgInfo.open(this.file_id,this.checkedLine[0]);
			},
			handleCopy(index, row) {
				this.copyData = row.name
				this.copy(this.copyData)
			},
			copy(data) {
				//创建一个input元素
				let input = document.createElement('input')
				//给input的内容复制
				input.value = data
				// 在body里面插入这个元素
				document.body.appendChild(input)
				// 选中input里面内容
				input.select()
				//执行document里面的复制方法
				document.execCommand("Copy")
				// 复制之后移除这个元素
				document.body.removeChild(input)
			},

			goDel() {
				console.log("zzzzzzzzzzzzzzzzzzzzzzz");
				console.log(this.contents);
				const params = new URLSearchParams();
				params.append('tem_id', 10);
				params.append('content', this.contents);

				axios.post(this.apiUrl + '/api/User/delCaseInfo?id=10', params)
					.then((response) => {

						this.$parent.$parent.temps = response.data.data;
					})
					.catch(function(error) {
						console.log(error);
					});

			},
			add() {
				this.$refs.EditsImgInfo.open(this.case_id,0);
 
			},
			goSet() {

				console.log(this.contents);
				var str = ";"
				for (var j = 0, len = this.contents.length; j < len; j++) {
					str += this.contents[j] + "  ";
				}
				this.$parent.textarea = str;
				this.dialogVisible = false;

			},
			gohandleSend2() {
				alert(3);
			},
			open(id) {
				 
				const params1 = new URLSearchParams();
				params1.append('file_id', id);
				axios.post(this.apiUrl + '/api/User/getCaseImgTemp', params1)
					.then((response) => {
						this.temps = response.data.data;
					})
					.catch(function(error) {
						console.log(error);
					});
				this.file_id = id
				
				this.case_id = id

				this.dialogVisible = true
			},
			handleSend3() {
				this.checkedGift.uid = sessionStorage.getItem('uid');
				this.checkedGift.bid = this.finfo.id;
				this.checkedGift.num = this.num;
				this.checkedGift.message = this.textarea;



				this.$refs.pay.open()
			},
			handleSend2() {
				const that = this
				this.$confirm('确认赠送？')
					.then(() => {
						console.log("++++++++")
						console.log(this.checkedGift)


						const params3 = new URLSearchParams();

						params3.append('uid', sessionStorage.getItem('uid'));

						params3.append('bid', this.id);

						params3.append('name', this.checkedGift.name);

						params3.append('url', this.checkedGift.url);

						params3.append('num', this.num);

						params3.append('message', this.textarea);


						console.log(params3);

						axios.post(this.apiUrl + '/api/user/giveGift', params3)
							.then((response) => {

								this.gifts = response.data.data;

							})
							.catch(function(error) {
								console.log(error);
							});



					})
					.catch(() => {});
			},
			handleSend() {
				const that = this
				this.$confirm('确认赠送？')
					.then(() => {
						that.dialogVisible = false
					})
					.catch(() => {});
			},
			formatGifts(data) {
				let num = Math.ceil(data.length / 12)
				this.gifts = new Array(num)
				for (let i = 0; i < num; i++) {
					this.gifts[i] = data.slice(i * 12, i * 12 + 12);
				}
			},
			check(item) {
				this.checkedGift = item
			}
		}
	}
</script>

<style scoped lang="scss">
	.gifts {
		.title {
			margin-bottom: 21px;
			color: #333;
		}

		.gift {
			width: 84px;
			height: 84px;
		}

		.el-icon-success {
			position: absolute;
			bottom: 5px;
			right: 50%;
			transform: translateX(42px);
			font-size: 17px;
			color: #FF2D2D;
		}

		.el-pagination {
			text-align: center;
		}
	}

	.info {
		&>div {
			margin-bottom: 10px;
		}
	}

	/deep/ .el-dialog__header {
		background-color: $green;
		height: 33px;
		padding: 0 0 0 27px;

		.el-dialog__title {
			line-height: 33px;
			font-size: 12px;
			color: #fff;
		}

		.el-dialog__headerbtn {
			top: 8px;

			.el-dialog__close {
				color: #fff;
			}
		}
	}

	/deep/ .el-dialog__body {
		padding: 18px 16px;
	}

	/deep/ .el-dialog__footer {
		text-align: center;

		.el-button--primary {
			width: 95px;
			height: 32px;
			background-color: $green;
			border-color: $green;
		}
	}

	/deep/ .el-pager li:last-child {
		border-color: $green
	}

	.el-col-4 {
		text-align: center;
		position: relative;
		margin-bottom: 16px;
	}

	.el-input-number--mini {
		width: 74px;
		line-height: 22px;
	}

	/*/deep/ .el-input__inner{*/
	/*  border: unset;*/
	/*}*/
	.el-input-number__decrease,
	.el-input-number__increase {
		background-color: transparent;
	}

	/deep/ .el-input--mini .el-input__inner {
		padding-left: 0;
		padding-right: 0;
	}

	/deep/ .el-input--mini .el-input__inner {
		height: 24px;
	}

	/deep/ .el-input-number--mini .el-input-number__decrease,
	/deep/ .el-input-number--mini .el-input-number__increase {
		width: 20px;
	}
</style>
