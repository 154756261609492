<template>
	<el-dialog title="1图片编辑框" :visible.sync="dialogVisible" width="1112px">
		<Pay ref="pay" :message1="checkedGift" />



		<div class="gifts clearFix">
			<el-upload action="#" list-type="picture-card"  
			:auto-upload="true" 
			:http-request="uploadss"
		    :file-list="fileList">

				<i slot="default" class="el-icon-plus"></i>
				<div slot="file" slot-scope="{file}">
					<img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
					<span class="el-upload-list__item-actions">
						<span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
							<i class="el-icon-zoom-in"></i>
						</span>
						<span v-if="!disabled" class="el-upload-list__item-delete" 
						@click="handleDownload(file)">
							<i class="el-icon-download"></i>
						</span>
						<span v-if="!disabled" class="el-upload-list__item-delete" 
						@click="handleRemove(file)">
							<i class="el-icon-delete"></i>
						</span>
					</span>

					<!-- <div v-if="fileList" style="position:relative">
							  <div v-show="mask" style="max-width:500px" class="mask" @mouseleave="mask = false" />
							  <div>
								<img style="width:100%" :src="imageUrl">
								<i v-show="mask" class="el-icon-delete deleteIcon" @click="clearList" />
							  </div>
					  
					  </div> 
					-->
				</div>

			</el-upload>

			<el-dialog :visible.sync="dialogVisible2" style="width: 5%;">
				<img width="20%" :src="dialogImageUrl" alt="">
			</el-dialog>

		</div>




		<div style="width: 20%; display:block; "><br /> <br />
			<el-button type="primary" @click="addUserCase()">保存</el-button>
		</div>

	</el-dialog>

</template>

<script>
	import axios from "axios";
	import data from '@/data/data.js'
	import Pay from './Pay.vue'
	import EditsInfo from '@/components/EditsInfo'
	export default {
		name: "EditsImgInfo",
		components: {
			Pay
		},
		props: ['info'],
		data() {
			return { 
				file_id: 0,
				line_id:0,
				fileList: [],
				dialogImageUrl: '',
				dialogVisible2: false,
				disabled: false,


				messageToHelloWorld: '我是父组件向下传递的信息',
				id: 0,
				dialogVisible: false,
				gifts: [],
				index: 0,
				checkedGift: {
					id: 60,
					uid: 60,
					name: '玫瑰花',
					url: require('../assets/gifts/5.jpg'),
					bid: 0,
					num: 0,
					message: '',

				},
				finfo: {},
				num: 1,
				textarea: '',
				temps: {},
				contents: '',
				case_id: 0,
				file: {},
			};
		},
		
		created() {
			this.fileList.push({
				//"url": 'http://doctor.com//uploads/20220705/46462c3a4ee32907.jpeg'
			})
			

		},
		

		watch: {
			// info: function(newVal, oldVal) {
			// 	this.finfo = newVal; //newVal即是chartData
			// 	//this.drawChart();
			// }
		},
		// mounted() {
		// 	//this.formatGifts(data.gifts)

		// 	//礼物列表  
		// },
		 
		methods: {

			//图片 上传
			handleRemove(file) {
				console.log(file);
				
				
				
				const formData = new FormData() 
				//这里是把上传接口所有的参数都append到formData中
				formData.append('img', file.url);
				
				
				//接口调用
				axios.post(this.apiUrl + '/api/user/delImgCaseTemp', formData).then((res) => {
					//this.imageUrl = this.apiUrl+res.data.data.pic;
					//this.from.logo = res.data.data.filename 
				this.showList()
				})
				
				
			},
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible2 = true;
			},
			handleDownload(file) {
				console.log(file);
			},

			uploadss(e) {
				//使用FormData 的最大优点就是可以异步上传二进制文件。
				//而图片，文件之类的文件是二进制，接口接收参数也是接收的二进制
				//可以先通过new关键字创建一个空的 FormData 对象，然后使用 append() 方法向该对象里添加字段
				//（字段的值可以是一个 Blob 对象，File对象或者字符串，剩下其他类型的值都会被自动转换成字符串）。

				//也可以用一个已有的form元素来初始化FormData对象

				this.getBase64(e.file).then((res) => {
					console.log("-------------------");
					console.log(res);

					this.fileList.push({
						"url": res
					})

					const formData = new FormData()

					//这里是把上传接口所有的参数都append到formData中
					formData.append('img', res)
					formData.append('uid', sessionStorage.getItem('uid'))
					formData.append('file_id', this.case_id)
					formData.append('line', 1)



					const config = {
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					}


					//接口调用
					axios.post(this.apiUrl + '/api/user/addImgCaseTemp', formData, config).then((res) => {
						//this.imageUrl = this.apiUrl+res.data.data.pic;
						//this.from.logo = res.data.data.filename 
					})


				})



			},

			getBase64(file) {
				return new Promise(function(resolve, reject) {
					const reader = new FileReader()
					let imgResult = ''
					reader.readAsDataURL(file)
					reader.onload = function() {
						imgResult = reader.result
					}
					reader.onerror = function(error) {
						reject(error)
					}
					reader.onloadend = function() {
						resolve(imgResult)
					}
				})
			},
			//图片上传	




			addUserCase() {
				//alert(this.dialogImageUrl);
				const params = new URLSearchParams();
				params.append('tem_id', this.case_id);
				params.append('content', this.contents);

				axios.post(this.apiUrl + '/api/User/addCaseInfo', params)
					.then((response) => {
						this.$parent.$parent.temps = response.data.data;
						this.dialogVisible = false;
					})
					.catch(function(error) {
						console.log(error);
					});
			},
			
			
			goAdd() {

				const params2 = new URLSearchParams();
				//params.append('uid', sessionStorage.getItem('uid')); 

				axios.post(this.apiUrl + '/api/Doctor/add?id=10', params2)
					.then((response) => {
						this.$parent.$parent.temps = response.data.data;
					})
					.catch(function(error) {
						console.log(error);
					});



				const params1 = new URLSearchParams();
				//params.append('uid', sessionStorage.getItem('uid')); 

				axios.post(this.apiUrl + '/api/Doctor/getCaseLable?id=10', params1)
					.then((response) => {

						this.$parent.$parent.temps = response.data.data;
					})
					.catch(function(error) {
						console.log(error);
					});


				this.dialogVisible = false;


			},
			goSet() {

				console.log(this.contents);
				var str = ";"
				for (var j = 0, len = this.contents.length; j < len; j++) {
					str += this.contents[j] + "  ";
				}
				this.$parent.textarea = str;
				this.dialogVisible = false;

			},



			gohandleSend2() {

			},


			open(id, line) {
				 
				this.file_id = id;
				this.line_id = line;
				this.dialogVisible = true;
				this.case_id = id;
				
				this.showList()
					

			},
			showList(){
				
				const params2 = new URLSearchParams();
				params2.append('file_id', this.file_id);
				params2.append('line_id', this.line_id); 
				
				axios.post(this.apiUrl + '/api/User/getCaseImgTempLine', params2)
					.then((response) => {
						this.fileList = []
						for(let i in response.data.data){ 
							this.fileList.push({
								"url": this.apiUrl+response.data.data[i].content
							})
						} 
						
						
					})
					.catch(function(error) {
						console.log(error);
					});
					
			},
			handleSend3() {
				this.checkedGift.uid = sessionStorage.getItem('uid');
				this.checkedGift.bid = this.finfo.id;
				this.checkedGift.num = this.num;
				this.checkedGift.message = this.textarea;



				this.$refs.pay.open()
			},
			handleSend2() {
				const that = this
				this.$confirm('确认赠送？')
					.then(() => {
						console.log("++++++++")
						console.log(this.checkedGift)


						const params3 = new URLSearchParams();

						params3.append('uid', sessionStorage.getItem('uid'));

						params3.append('bid', this.id);

						params3.append('name', this.checkedGift.name);

						params3.append('url', this.checkedGift.url);

						params3.append('num', this.num);

						params3.append('message', this.textarea);


						console.log(params3);

						axios.post(this.apiUrl + '/api/user/giveGift', params3)
							.then((response) => {

								this.gifts = response.data.data;

							})
							.catch(function(error) {
								console.log(error);
							});



					})
					.catch(() => {});
			},
			handleSend() {
				const that = this
				this.$confirm('确认赠送？')
					.then(() => {
						that.dialogVisible = false
					})
					.catch(() => {});
			},
			formatGifts(data) {
				let num = Math.ceil(data.length / 12)
				this.gifts = new Array(num)
				for (let i = 0; i < num; i++) {
					this.gifts[i] = data.slice(i * 12, i * 12 + 12);
				}
			},
			check(item) {
				this.checkedGift = item
			}
		}
	}
</script>

<style scoped lang="scss">
	//--图片
	// 上传图片框样式
	.el-upload--picture-card {
		// width: 50px!important;
		// height: 50px!important;
	}

	//--图片





	.gifts {
		.title {
			margin-bottom: 21px;
			color: #333;
		}

		.gift {
			width: 84px;
			height: 84px;
		}

		.el-icon-success {
			position: absolute;
			bottom: 5px;
			right: 50%;
			transform: translateX(42px);
			font-size: 17px;
			color: #FF2D2D;
		}

		.el-pagination {
			text-align: center;
		}
	}

	.info {
		&>div {
			margin-bottom: 10px;
		}
	}

	/deep/ .el-dialog__header {
		background-color: $green;
		height: 33px;
		padding: 0 0 0 27px;

		.el-dialog__title {
			line-height: 33px;
			font-size: 12px;
			color: #fff;
		}

		.el-dialog__headerbtn {
			top: 8px;

			.el-dialog__close {
				color: #fff;
			}
		}
	}

	/deep/ .el-dialog__body {
		padding: 18px 16px;
	}

	/deep/ .el-dialog__footer {
		text-align: center;

		.el-button--primary {
			width: 95px;
			height: 32px;
			background-color: $green;
			border-color: $green;
		}
	}

	/deep/ .el-pager li:last-child {
		border-color: $green
	}

	.el-col-4 {
		text-align: center;
		position: relative;
		margin-bottom: 16px;
	}

	.el-input-number--mini {
		width: 74px;
		line-height: 22px;
	}

	/*/deep/ .el-input__inner{*/
	/*  border: unset;*/
	/*}*/
	.el-input-number__decrease,
	.el-input-number__increase {
		background-color: transparent;
	}

	/deep/ .el-input--mini .el-input__inner {
		padding-left: 0;
		padding-right: 0;
	}

	/deep/ .el-input--mini .el-input__inner {
		height: 24px;
	}

	/deep/ .el-input-number--mini .el-input-number__decrease,
	/deep/ .el-input-number--mini .el-input-number__increase {
		width: 20px;
	}
</style>
