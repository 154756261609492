import { render, staticRenderFns } from "./AddImgCaseInfo.vue?vue&type=template&id=0d1ea14f&scoped=true&"
import script from "./AddImgCaseInfo.vue?vue&type=script&lang=js&"
export * from "./AddImgCaseInfo.vue?vue&type=script&lang=js&"
import style0 from "./AddImgCaseInfo.vue?vue&type=style&index=0&id=0d1ea14f&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d1ea14f",
  null
  
)

export default component.exports